/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'react-bootstrap'
import { useRouter } from 'next/router'

import { getHeaderMenu } from '@shared-submodule/sm-libs/menu'
import CustomLink from '@shared/components/customLink'
import styles from './style.module.scss'
import useOutsideClick from '@shared/hooks/useOutsideClick'
import CDropdown from './customDropdown'


function HeaderMenu({ toggleSideMenu }) {
  const menuItems = getHeaderMenu()
  const menu = menuItems.slice(0, 6)
  const router = useRouter()

  const [openDropdownId, setOpenDropdownId] = useState(null)

  function handleClick() {
    if (window.innerWidth < 992) {
      toggleSideMenu()
    }
  }

  const handleClickOutside = e => {
    setOpenDropdownId(null)
  }

  const dropdownRef = useOutsideClick(handleClickOutside)

  const handleDropdownToggle = id => {
    setOpenDropdownId(openDropdownId === id ? null : id)
  }

  return (
    <div className={`${styles.headerMenu} d-lg-flex text-nowrap`}>
      {/* {menuItems?.length !== 0 &&
        <h4 className="d-lg-none text-center mb-1"><span>Menu</span></h4>
      } */}
      <ul className="d-flex mb-0 m-lg-auto position-relative">
        {menu?.map((m, i) => {
          if (m?.oChildren?.length) {
            return (
              <li key={m?._id}>
                {/* <Dropdown
                  className={styles.dropdown}
                  show={openDropdownId === m._id}
                  onClick={() => handleDropdownToggle(m._id)}
                  >
                  <Dropdown.Toggle variant="link" id={m._id} className={styles.navMore} ref={dropdownRef}>
                    {m.sTitle}
                  </Dropdown.Toggle>
                  <Dropdown.Menu id={m._id} className={styles.dropdownMenu}>
                    {m?.oChildren.map(c => {
                      return (
                        <CustomLink key={c?._id} href={'/' + c?.sSlug} passHref>
                          <Dropdown.Item onClick={handleClick}>{c?.sTitle}</Dropdown.Item>
                        </CustomLink>
                      )
                    })}
                  </Dropdown.Menu>
                </Dropdown> */}
                <CDropdown data={m}/>
              </li>
            )
          } else {
            return (
              <li key={m?._id}>
                <CustomLink href={m?.eMenuType === 'outside' ? m?.sUrl || '/' + m?.sSlug : '/' + m?.sSlug}>
                  <a
                    target={m?.eUrlTarget}
                    className={`${styles.navItem} ${router.asPath.slice(0, -1) === '/' + m?.sSlug ? styles.active : ''} ${
                      m?.sSlug === 'live-scores' ? styles.liveUpdate : ''
                    } `}>
                    {m?.sTitle}
                  </a>
                </CustomLink>
              </li>
            )
          }
        })}
      </ul>
    </div>
  )
}

HeaderMenu.propTypes = {
  toggleSideMenu: PropTypes.func
}

export default HeaderMenu
